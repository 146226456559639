import React, { memo, useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { book } from '../../../../routers/books';
import { useStyles } from '../../styles';
import { useAppSelector } from '../../../../store/hooks';
import { Hosts } from '../../../../constants/hostName';

// interface IMultipleJobListingProps {}
const MultipleJobListing = () => {
  const classes = useStyles();
  const [multipleJobsLink, setMultipleJobsLink] = useState<string>(book.jobs);
  const { siteNumber } = useAppSelector(({ pathnameSettings }) => ({
    siteNumber: pathnameSettings.siteNumber
  }));

  useEffect(() => {
    if (siteNumber) {
      switch (siteNumber) {
        case Hosts.NOSH:
          setMultipleJobsLink(book.jobsNosh);
          break;
        case Hosts.BREWBOUND:
          setMultipleJobsLink(book.jobsBrewbound);
          break;
        default:
          setMultipleJobsLink(book.jobs);
      }
    }
  }, [siteNumber]);

  return (
    <div className={classes.multiple_job_listing}>
      <Typography
        sx={{ mb: 2 }}
        variant="h6"
        fontWeight={800}
      >
        SAVE WITH BULK JOB LISTING PACKAGES
      </Typography>
      <Typography
        variant="body2"
      >
        Purchase multiple job listings and SAVE. All job listing packages are good for 24 months and can be used immediately.
        {' '}
        <a
          href={multipleJobsLink}
          className={classes.text_error}
        >
          View bulk listing packages/savings
        </a>
      </Typography>
      <Typography
        sx={{ mb: 2, mt: 4 }}
        variant="h6"
        fontWeight={800}
      >
        NEED TO PAY BY ACH OR INVOICE?
      </Typography>
      <Typography
        variant="body2"
      >
        ACH and invoices are available for certain customers.
        {' '}
        <a
          href="mailto:jobboard@bevnet.com?subject=Job Board invoicing"
          className={classes.text_error}
        >
          Contact us to find out more.
        </a>
      </Typography>
    </div>
  );
};

export default memo(MultipleJobListing);
