import React, { memo, useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import cookies from 'js-cookie';
import { useDispatch } from 'react-redux';

import { BasicLayout, cookieNames } from '@monorepo/common';

import { useAppSelector } from '../../../store/hooks';

import { useStyles } from '../styles';
import { useClearDataQuery } from '../../../services/servicePostAJob';
import { dropStepper } from '../../../features/sliceStepper';
import { Hosts } from '../../../constants/hostName';

const forBevnet: string = 'Post a beverage industry job listing on BevNET’s Job Board and reach thousands of monthly job board viewers, including skilled beverage professionals who know the industry.';
const forNosh: string = 'Post a natural food industry job listing on NOSH’s Job Board and reach thousands of monthly job board viewers, including skilled food industry professionals who know the industry.';
const forBrewbound: string = 'Post a beer industry job listing on Brewbound’s Job Board and reach thousands of monthly job board viewers, including skilled beer and beyond professionals who know the industry.';
const discountText: string = 'Save $50 per listing for a limited time!';
// interface IDescriptionJobsProps {}
const DescriptionJobs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [titleStep] = useState<string>('Post a Job Listing');
  const [textForHost, setTextForHost] = useState<string>('');
  const [isStartClear, setIsStartClear] = useState<boolean>(false);
  const { isShowDopButton, siteNumber } = useAppSelector(({ stepperOptions, pathnameSettings }) => ({
    isShowDopButton: stepperOptions.isShowDropButton,
    siteNumber: pathnameSettings.siteNumber
  }));
  const { isLoading, isSuccess } = useClearDataQuery(null, {
    skip: !isStartClear
  });
  const drop = useCallback(() => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure that you want to start over? You will not longer be able to edit the current listing.')) {
      if (cookies.get(cookieNames.tempJob)) {
        setIsStartClear(true);
      }
    }
  }, []);

  useEffect(() => {
    if (isSuccess && cookies.get(cookieNames.tempJob) && isStartClear) {
      setIsStartClear(false);
      cookies.remove(cookieNames.tempJob);
      dispatch(dropStepper({
        isDrop: true
      }));

      const timeout = setTimeout(() => {
        dispatch(dropStepper({
          isDrop: false
        }));
        clearTimeout(timeout);
      }, 3000);
    }
  }, [isSuccess, isStartClear]);

  useEffect(() => {
    if (siteNumber) {
      if (siteNumber === Hosts.NOSH) {
        setTextForHost(forNosh);
        return;
      }
      if (siteNumber === Hosts.BREWBOUND) {
        setTextForHost(forBrewbound);
        return;
      }
      setTextForHost(forBevnet);
    }
    setTextForHost(forBevnet);
  }, [siteNumber]);

  return (
    <BasicLayout isLoading={isLoading}>
      <div className={classes.description_page}>
        <Typography
          sx={{ mb: 3 }}
          variant="h1"
          fontWeight={800}
        >
          {titleStep}
        </Typography>

        <p>
          {textForHost}
        </p>

        {discountText && (
          <p><strong>{discountText}</strong></p>
        )}

        {isShowDopButton && (
        <Typography
          color="red"
          style={{
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
          sx={{ mt: 2 }}
          variant="body1"
          onClick={drop}
        >
          Clear this job listing and start over
        </Typography>
        )}
      </div>
    </BasicLayout>
  );
};

export default memo(DescriptionJobs);
